import * as actionTypes from './actionTypes';


export const addComment = (postId, comments) => ({
    type: actionTypes.ADD_COMMENT,
    postId,
    comments
});

export const removeComment = (postId, index) => ({
    type: actionTypes.REMOVE_COMMENT,
    postId,
    index
});