import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { auth, db } from './firebase';
import * as actions from './store/actions/';

import PhotoGrid from './components/PhotoGrid/PhotoGrid';
import Single from './components/Single/Single';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Aboutme from './components/Aboutme/Aboutme';
import Modal from './components/Modal/Modal';
import Login from './components/Login/Login';
import AddPic from './components/AddPic/AddPic';
import Spinner from './components/Spinner/Spinner';

import './App.css';
import './mobile.css';

class App extends React.Component {
  state = {
    spinner: false
  }
  componentDidMount() {
    //check if user is authenticated
    auth().onAuthStateChanged(user => {
      if (user) {
        this.props.loginSuccess(user.displayName);
      } else {
        //user logged out
        this.props.logoutSuccess();
      }
    });

    //load posts
    this.setState({spinner: true})
    const addPostsToState = (post, id) => {
      this.props.addPost({ ...post, id });
      this.setState({ spinner: false });
    }

    //load comments
    const addCommentsToState = (comments, id) => {
      this.props.addComment(id, comments)
    }

    const updatePost = (post, id) => {
      this.props.updatePost({...post, id});
    };

    //create the query to load the posts and listen for new ones
    const queryPosts = db.collection('posts').orderBy('timestamp');

    //start listening to query
    queryPosts.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'removed') {
          //deletePost(change.doc.id)
        }
        if (change.type==='added') {
          addPostsToState(change.doc.data(), change.doc.id);
        }
        if (change.type === 'modified') {
          // handle the change in posts (it updates the URL of image and other properties)
          updatePost(change.doc.data(), change.doc.id)
        }
      });
    });

    //create the query to load the comments and listen for new ones
    const queryComments = db.collection('comments');
    
    //start listening to comments
    queryComments.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'removed') {
          // delete the comment
        }
        if (change.type === 'added') {
          // update the comment state UPDATE THE STATE ONLY!!!
          addCommentsToState(change.doc.data().currentComments, change.doc.id)
        }
        if (change.type === 'modified') {
          //update the comment state
          addCommentsToState(change.doc.data().currentComments, change.doc.id)
        }
      })
    })
    
  }
  render() {
    const grid = this.state.spinner ? Spinner : PhotoGrid;
    return (
      <>
        <div className="main-content">
          <Header />
          <h1>
            <Link to='/'>MariannaSTAGRAM</Link>
          </h1>
          <Modal show={this.props.showModal}>
            {this.props.modal === 'signup' || this.props.modal === 'login'?
            <Login signUp={this.props.modal === 'signup'} />:
            <AddPic />}
          </Modal>
          <Switch>
            <Route path='/view/:postId' component={Single} />
            <Route path='/aboutme' component={Aboutme} />
            <Route exact path='/' component={grid} />
          </Switch>
        </div>
        
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  showModal: state.auth.showModal,
  modal: state.auth.modal,
  posts: state.posts,
  comments: state.comments
});

const mapDispatchToProps = dispatch => ({
  addPost: post => dispatch(actions.addPost(post)),
  updatePost: post=>dispatch(actions.updatePost(post)),
  incrementLikes: (index) => dispatch(actions.incrementLikes(index)),
  addComment: (postId, author, comment) => dispatch(actions.addComment(postId, author, comment)),
  removeComment: (postId, index) => dispatch(actions.removeComment(postId, index)),
  loginSuccess: username => dispatch(actions.loginSuccess(username)),
  logoutSuccess: () => dispatch(actions.logoutSuccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
