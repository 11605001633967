import React from 'react';
import Dropzone from 'react-dropzone';
import { storage, db, firestore } from '../../firebase';
import { connect } from 'react-redux';

import Spinner from '../Spinner/Spinner';
import * as actions from '../../store/actions/';


import classes from './AddPic.module.css'

class AddPic extends React.Component {
    state = {
        file: null,
        imgSrc: null,
        description: '',
        uploadError: null,
        spinner: false
    }
    shouldComponentUpdate() {
        return true;
        //TO DO: don't update when the modal shows up
    }
    sendPic = e => {
        e.preventDefault();
        this.setState({ spinner: true });
        // 1. Create a post
        db.collection('posts').add({
            caption: this.state.description,
            likes: 0,
            timestamp: firestore.FieldValue.serverTimestamp()
        })
            .then(postRef => {
                // 2. Upload the image to Cloud Storage
                const filePath = `${postRef}/${this.state.file.name}`;
                return storage().ref(filePath).put(this.state.file)
                    // 3. Generate public URL for the image
                    .then(fileSnapshot => {
                        return fileSnapshot.ref.getDownloadURL()
                            // 4. Update post info
                            .then(url => postRef.update({
                                imageUrl: url,
                                storageUri: fileSnapshot.metadata.fullPath
                            })
                                .then(() => this.props.hideModal())
                            )
                    })
            }) 
            .catch(err => {
                console.log(err);
                this.setState({ uploadError: err }); //IMPLEMENT ERROR
            })
    }
    handleDescription = e => {
        this.setState({ description: e.currentTarget.value })

    }
    handlePic = files => {
        if (files[0]) {
            this.setState({ file: files[0] });
            this.setState({ imgSrc: URL.createObjectURL(this.state.file) })
        }

    }

    render() {
        const getSize = number => {
            if (number < 1024) {
                return number + 'bytes';
            } else if (number > 1024 && number < 1048576) {
                return (number / 1024).toFixed(1) + 'KB';
            } else if (number > 1048576) {
                return (number / 1048576).toFixed(1) + 'MB';
            }
        }
        const fileName = fileName => fileName.length > 23 ? `${fileName.substring(0, 16)}...${fileName.substring(fileName.length - 5)}` : fileName; 
        const pic = this.state.imgSrc ?
            (<>
                <div className={classes.preview}>
                    <div className={classes.previewDiv}>
                        <img src={this.state.imgSrc} className={classes.picPreview} alt='preview' />
                    </div>
                    <div>
                        <p>
                            name: {fileName(this.state.file.name)}
                        </p>
                        <p>
                            size: {getSize(this.state.file.size)}
                        </p>
                    </div>
                </div>
                <textarea className={classes.description} rows='3' placeholder='Add some description here...' onChange={this.handleDescription} value={this.state.description} />
            </>) :
            <p>No picture selected yet!</p>
        let form = <Spinner />
        if (!this.state.spinner) form = (
            <form onSubmit={this.sendPic}>
                <div>
                    <Dropzone
                        multiple={false}
                        accept='image/*'
                        onDrop={this.handlePic}
                    >
                        {({ getRootProps, getInputProps }) => {
                            return (
                                <div
                                    {...getRootProps({ className: 'dropzone' })}
                                >
                                    <input {...getInputProps()} />
                                    {
                                        <p>Try dropping some files here, or click to select files to upload.</p>
                                    }
                                </div>
                            )
                        }}
                    </Dropzone>
                </div>
                <div>
                    {pic}
                </div>
                <div>
                    <button type='submit' className={classes.AddPic} title="Add an image" disabled={!this.state.file}>
                        Upload
                </button>
                </div>
            </form>
        );
        return (
            <div className={classes.AddPic}>
                {form}
            </div>
        );
    }

};

const mapDispatchToProps = dispatch => ({
    hideModal: () => dispatch(actions.hideModal())
});
export default connect(null, mapDispatchToProps)(AddPic);