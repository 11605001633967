import React, { Component } from 'react';
import { connect } from 'react-redux';

import { db } from '../../firebase';
import * as actions from '../../store/actions/';

import classes from './Comments.module.css';

class Comments extends Component {
    comment = React.createRef();
    commentForm = React.createRef();

    handleSubmit = e => {
        e.preventDefault();
        const { postId } = this.props.match.params;
        const comment = { author: this.props.username, comment: this.comment.current.value };
        let currentComments = [];
        if (this.props.comments[postId]) {
            currentComments = [...this.props.comments[postId]];
        }
        currentComments.push(comment);
        const comments = db.collection('comments').doc(postId);
        comments.set({ currentComments })
            .then(() => {
                //update localState or not ??
            })
            .catch(err => console.error(err));
        this.commentForm.current.reset();
    }

    showModal = () => {
        this.props.showModal('login');
    }
    renderComments = (comment, index) => (
        <div className="comment" key={index}>
            <p>
                <strong>{comment.author}</strong>
                {comment.comment}
                <button
                    onClick={() => this.props.removeComment(this.props.match.params.postId, index)}
                    className='remove-comment'
                >&times;</button>
            </p>
        </div>)

    render() {
        let form = (
            <form
                ref={this.commentForm}
                className="comment-form"
                onSubmit={this.handleSubmit}
            >
                <input type="text" placeholder={this.props.username} readOnly />
                <input type="text" ref={this.comment} placeholder='comment' />
                <input type="submit" hidden />
            </form>
        );
        if (!this.props.authorized) form = <p>You have to <span className={classes.Login} onClick={this.showModal}>LOG IN</span> to comment</p>
        return (
            <>
                <div className="comments">
                    {this.props.postComments.map(this.renderComments)}
                    {form}
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    authorized: state.auth.authorized,
    showModal: state.auth.showModal,
    username: state.auth.username,
    comments: state.comments
});

const mapDispatchToProps = dispatch => ({
    showModal: (content) => dispatch(actions.showModal(content))
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);