import React from 'react';
import { Link } from 'react-router-dom';

const Subheader = (props) => {

    return (
        <div className="subheader">
            <div className="headpic">
                <img src="https://mariannaalexandraki.online/MA.photos/img/profile.jpg" alt="Marianna Alexandraki" />
            </div>
            <div>
                <h2>
                    <strong>Marianna Alexandraki</strong>
                </h2>
                <a href="https://www.instagram.com/malexandraki1975/" target="_blank" rel="noopener noreferrer"><button>My Instagram</button></a>
                <button>
                    <Link to='/aboutme'>
                        About me &rarr;
                </Link>
                </button>
            </div>

        </div>
    );
};

export default Subheader;