import * as actionTypes from '../actions/actionTypes';
import { db } from '../../firebase';


const initialState = [];

const addPost = (state, action) => {
    return [...state, action.post];
}

const updatePost = (state, action) => {
    //if this is initial update there might not be id yet
    if (!action.post.id) return state;
    //find index
    const index = state.findIndex(post => post.id === action.post.id);
    //update
    return [
        ...state.slice(0, index),
        {
            ...action.post
        },
        ...state.slice(index + 1)

    ];
}

const incrementLikes = (state, action) => {
    const { id, likes } = state[action.index];
    db.collection('posts').doc(id).update({ likes: likes + 1 });
    return state;
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_POST: return addPost(state, action);
        case actionTypes.UPDATE_POST: return updatePost(state, action);
        case actionTypes.INCREMENT_LIKES: return incrementLikes(state, action);
        // case actionTypes.ADD_PICTURE: return { ...state}
        default: return state;
    }
}
