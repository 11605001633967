import React from 'react';

import classes from "./Input.module.css";

const Input = (props) => {
    const inputClasses = [classes.InputElement];
    let validationError = null;
    if (props.invalid && props.shouldValidate && props.touched) inputClasses.push(classes.Invalid);
    if (props.invalid && props.touched) {validationError = <p className={classes.ValidationError}>Please enter {props.elementConfig.placeholder}!</p>}
    if (props.invalid && props.touched && props.elementConfig.placeholder === 'Password') {validationError = <p className={classes.ValidationError}>Password needs at least 6 characters!</p>}
    if (props.invalid && props.touched && props.elementConfig.placeholder === 'Password again') {validationError = <p className={classes.ValidationError}>Please enter the same password!</p>}
    let inputElement = null;
    switch (props.elementType) {
        case ('input'):
            inputElement = (
                <input 
                    className={inputClasses.join(' ')} 
                    {...props.elementConfig} 
                    value={props.value}
                    onChange={props.changed}
                />
            );
            break;
        case ('textarea'):
            inputElement = (
                <textarea 
                    className={inputClasses.join(' ')} 
                    {...props.elementConfig} 
                    value={props.value}
                    onChange={props.changed}
                />
            );
            break;
        case ('select'):
            inputElement = (
                <select
                    className={inputClasses.join(' ')}
                    value={props.value}
                    onChange={props.changed}
                >
                    {props.elementConfig.options.map(option => (
                        <option
                            key={option.value}
                            value={option.value}
                        >
                        {option.displayValue}
                        </option>
                    ))}
                </select>
            );
            break;
        default:
            inputElement = (
                <input
                    className={inputClasses.join(' ')}
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed}
                />
            );
    }
    return (
        <div className={classes.Input}>
            <label className={classes.Label}>{props.label}</label>
            {inputElement}
            {validationError}
        </div>
    );
};

export default Input;