import * as actionTypes from '../actions/actionTypes';

const initialState = {};

const addComment = (state, action) => {
    const currentComments = [];
    action.comments.forEach(comment => {
        currentComments.push({author: comment.author, comment: comment.comment})
    })
    return currentComments;
}

const postComments = (state = [], action) => {
    switch (action.type) {
        case actionTypes.ADD_COMMENT:
            return addComment(state, action);
            
        case actionTypes.REMOVE_COMMENT:
            return [
                ...state.slice(0, action.index),
                ...state.slice(action.index + 1)
            ]
        default:
            return state;
    }
}

export default (state = initialState, action) => {
    if (typeof action.postId !== 'undefined') {
        return {
            ...state,
            [action.postId]: postComments(state[action.postId], action)
        };
    }
    return state;
}
