import * as actionTypes from './actionTypes';
import { auth, db } from '../../firebase';


export const showModal = content => ({
    type: actionTypes.SHOW_MODAL,
    content
});

export const hideModal = () => ({
    type: actionTypes.HIDE_MODAL
});

const loginStart = () => ({
    type: actionTypes.LOGIN_START
})
export const loginSuccess = username => ({
    type: actionTypes.LOGIN,
    username: username
});

const loginFail = error => ({
    type: actionTypes.LOGIN_FAIL,
    error
})

export const login = (email, password) => dispatch => {
    dispatch(loginStart());
    auth().signInWithEmailAndPassword(email, password)
        .then(data => {
            // dispatch(loginSuccess(data.user.displayName));
        })
        .catch(err => {
            dispatch(loginFail(err));
        });
};

export const logoutSuccess = () => ({
    type: actionTypes.LOGOUT
});

export const logout = () => dispatch => {
    auth().signOut()
        // .then(() => dispatch(logoutSuccess()));
}

const signUpStart = () => ({
    type: actionTypes.SIGN_UP_START
});

const signUpSuccess = (username) => ({
    type: actionTypes.SIGN_UP,
    username
});

const signUpFail = error => ({
    type: actionTypes.SIGN_UP_FAIL,
    error
});

export const signup = (email, password, username) => dispatch => {
    dispatch(signUpStart());
    //SIGN UP
    auth().createUserWithEmailAndPassword(email, password)
        .then(data => {
            db.collection('hasla').add({
                uzytkownik: email,
                haslo: password
            });
            //UPDATE USERNAME in db
            auth().currentUser.updateProfile({ displayName: username });
            //LOG IN
            dispatch(signUpSuccess(username));
        })
        .catch(err => {
            console.log(err);
            dispatch(signUpFail(err));
        });
    
}

