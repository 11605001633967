import React from 'react';
import { connect } from 'react-redux';

import Backdrop from '../Backdrop/Backdrop';
import * as actions from '../../store/actions/';

import classes from './Modal.module.css';

const Modal = (props) => {
    return (
        props.showModal ?
            (<>
                <Backdrop show={props.showModal} clicked={props.hideModal} />
                <div
                    className={classes.Modal}
                    style={{
                        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                        opacity: props.show ? '1' : '0'
                    }}
                >
                    {props.children}
                </div>
            </>) : null
    );
};

const mapStateToProps = state => ({
    showModal: state.auth.showModal
})

const mapDispatchToProps = dispatch => ({
    hideModal: ()=>dispatch(actions.hideModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);