export const updateObject = (oldObj, updatedProperties) => ({
    ...oldObj,
    ...updatedProperties
});

export const checkValidity = (value, rules, oldPass) => {
    let isValid = true;
    if (!rules) return true;
    if (rules.required) isValid = value.trim() !== '' && isValid;
    if (rules.minLength) isValid = value.trim().length >= rules.minLength && isValid;
    if (rules.maxLength) isValid = value.trim().length <= rules.maxLength && isValid;
    if (rules.isEmail) {
        const pattern = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.samePassword) {
        isValid = value === oldPass;
    }
    return isValid;
};
