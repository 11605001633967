import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/';


const Header = (props) => {
    const login = () => {
        props.showModal('login');
    }
    const signup = () => {
        props.showModal('signup')
    }
    const logout = () => {
        props.logout();
    }
    const addPicture = () => {
        props.showModal();
    }
    let buttons = (
        <>
            <button className="login" onClick={login}>Log in</button>
            <button className="signup" onClick={signup}>Sign up</button>
        </>
    );
    if (props.authorized) {
        buttons = (
            <>
                <button className="login" onClick={logout}>Logout</button>
                <button className="signup" onClick={addPicture}>+ Add picture</button>
            </>
        );
    }
    return (
        <>
            <header>
                {buttons}
            </header>
            <hr />
        </>
    );
};

const mapStateToProps = state => ({
    authorized: state.auth.authorized,
    showModal: state.auth.showModal
});

const mapDispatchToProps = dispatch => ({
    showModal: (content)=>dispatch(actions.showModal(content)),
    logout: () => dispatch(actions.logout()),
    addPicture: ()=>dispatch(actions.addPicture())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);