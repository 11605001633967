import * as actionTypes from '../actions/actionTypes';

const initialState = {
    authorized: false,
    showModal: false,
    modal: null,
    error: null,
    spinner: false,
    username: null
}


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_START: return { ...state, error: null, spinner: true };
        case actionTypes.LOGIN: return { ...state, authorized: true, spinner: false, modal: null, username: action.username || state.username, showModal: false };
        case actionTypes.LOGIN_FAIL: return { ...state, spinner: false, error: action.error };
        case actionTypes.LOGOUT: return { ...state, authorized: false, username: null };
        case actionTypes.SIGN_UP_START: return { ...state, spinner: true }
        case actionTypes.SIGN_UP_FAIL: return { ...state, spinner: false, error: action.error };
        case actionTypes.SIGN_UP: return { ...state, spinner: false, authorized: true, showModal: false, modal: null, error: null, username: action.username };
        case actionTypes.SHOW_MODAL: return { ...state, showModal: true, modal: action.content, error: null }
        case actionTypes.HIDE_MODAL: return { ...state, showModal: false, modal: null }
        default: return state;
    }
}
