import React, { Component } from 'react';
import { connect } from 'react-redux';

import Subheader from '../Subheader/Subheader';
import Photo from '../Photo/Photo';
import Spinner from '../Spinner/Spinner';

import * as actions from '../../store/actions/';

class PhotoGrid extends Component {
    render() {
        const grid = this.props.posts ?
            
                this.props.posts.map((post, index) => {
                    return (<Photo
                        {...this.props}
                        post={post}
                        index={index}
                        key={index}
                    />)
                })
             :
            <Spinner />
        return (
            <>
                <Subheader />
            <div className="photo-grid">
                {grid}
                    
                </div>
                </>
        );
    }
}

const mapStateToProps = state => ({
    posts: state.posts,
    comments: state.comments
});

const mapDispatchToProps = dispatch => ({
    incrementLikes: (index) => dispatch(actions.incrementLikes(index)),
    addComment: (postId, author, comment) => dispatch(actions.addComment(postId, author, comment)),
    removeComment: (postId, index) => dispatch(actions.removeComment(postId, index))
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGrid);