import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBkJ5rfvTDL_VPDXvxG87QHEjaz_e0FetI",
    authDomain: "mariannastagram.firebaseapp.com",
    databaseURL: "https://mariannastagram.firebaseio.com",
    projectId: "mariannastagram",
    storageBucket: "mariannastagram.appspot.com",
    messagingSenderId: "862990445682",
    appId: "1:862990445682:web:21765d80427795620d66eb"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();

export const firestore = firebase.firestore;

export const auth = firebase.auth;

export const storage = firebase.storage;



