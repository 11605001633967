export const ADD_POST = 'ADD_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const INCREMENT_LIKES = 'INCREMENT_LIKES';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const ADD_PICTURE = 'ADD_PICTURE';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_START = 'SIGN_UP_START';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
