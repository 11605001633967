import React from 'react';
import { Link } from 'react-router-dom';

const Footer = (props) => {
    const style={width: '100%', }
    return (
        <div className="footer">
            <hr style={style} />
            <footer>

                <div className="links">
                    <ul>
                        <li>
                            <Link to='/aboutme'>About</Link>
                        </li>
                        <li>
                            <a href="https://mariannaalexandraki.com" target="_blank" rel="noopener noreferrer">MariannaAlexandraki.com</a>
                        </li>
                        <li>
                            <a href="https://mariannaalexandraki.online" target="_blank" rel="noopener noreferrer">MariannaAlexandraki.online</a>
                        </li>
                    </ul>
                </div>
                <div className='italics'>
                    2019 &copy; Marianna Alexandrakis
            </div>
            </footer>
        </div>
    );
};

export default Footer;