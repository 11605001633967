import * as actionTypes from './actionTypes';

export const addPost = post => ({
    type: actionTypes.ADD_POST,
    post
});

export const updatePost = post => ({
    type: actionTypes.UPDATE_POST,
    post
})
export const incrementLikes = index => ({
    type: actionTypes.INCREMENT_LIKES,
    index
});



export const addPicture = () => ({
    type: actionTypes.ADD_PICTURE
});