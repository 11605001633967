import React from 'react';
import { Link } from 'react-router-dom';

const Aboutme = (props) => {
    const center = { textAlign: 'center' };
    const justify = { textAlign: 'justify' };
    return (
        <div className="aboutme">
            <header>
                <button>
                    <Link to='/'>Go back</Link>
                </button>
            </header>
            <main>
                <p style={center}>
                    Hello there my fans!!!
                </p>
                <p style={justify}>
                    My name is <strong>Marianna Alexandrakis</strong> and I wanted to show myself to the world. Not just the disgusting physical appearance but also my horrible sickening soul, which you will find described on my other websites listed below. In short - I'm a liar, a cheat, a thief and a racist.
                    <br/>
                    If you want to reach out to me you can do so by sending a letter to this address:
                </p>
                <address>
                    Marianna Alexandraki<br />
                    8412 57th Rd Apt 2 <br />
                    Elmhurst, NY 11373
                    </address>
            </main>
        </div>
    );
};

export default Aboutme;