import React from 'react';
import { connect } from 'react-redux';

import Input from '../Input/Input';
import Spinner from '../Spinner/Spinner';
import { updateObject, checkValidity } from '../../shared/utility';
import * as actions from '../../store/actions/';

import classes from './Login.module.css';

class Login extends React.Component {
    state = {
        controls: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Your Name'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 2
                },
                valid: false,
                touched: false
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Your E-Mail'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            },
            passwordRepeat: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password again'
                },
                value: '',
                validation: {
                    required: true,
                    samePassword: true
                },
                valid: false,
                touched: false
            }
        },
        isSignUp: false
    }

    componentDidMount() {
        if (this.props.signUp) this.setState({ isSignUp: true });
    }

    inputChangeHandler = (event, inputIdentifier) => {
        const updatedControls = updateObject(this.state.controls, {
            [inputIdentifier]: updateObject(this.state.controls[inputIdentifier], {
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.controls[inputIdentifier].validation, this.state.controls.password.value),
                touched: true
            })
        });
        this.setState({ controls: updatedControls });
    }

    switchToSignUp = () => {
        this.props.showModal('signup');
        this.setState({ isSignUp: true });
    }

    login = e => {
        e.preventDefault();
        this.props.login(this.state.controls.email.value, this.state.controls.password.value);
    }
    signup = e => {
        e.preventDefault();
        this.props.signup(this.state.controls.email.value, this.state.controls.password.value, this.state.controls.name.value);
    }

    render() {
        let errorMsg = null;
        if (this.props.error) errorMsg = (
            <p style={{ color: "red" }}>{this.props.error.message}</p>
        )
        const inputs = [];
        let form = null;

        for (const key in this.state.controls) {
            if (this.state.isSignUp) {
                inputs.push({
                    id: key,
                    config: this.state.controls[key]
                });
            } else {
                if (key !== 'name' && key !== 'passwordRepeat') {
                    inputs.push({
                        id: key,
                        config: this.state.controls[key]
                    });
                }
            }
        }
        let formInputs = inputs.map(input => (
            <Input
                key={input.id}
                elementType={input.config.elementType}
                elementConfig={input.config.elementConfig}
                value={input.config.value}
                invalid={!input.config.valid}
                shouldValidate={input.config.validation}
                touched={input.config.touched}
                changed={e => this.inputChangeHandler(e, input.id)}
            />
        ));
        const formButtonEnabled = inputs.map(input => this.state.controls[input.id].valid).reduce((prev, next) => prev && next);
        switch (this.props.modal) {
            case 'login':
                form = (
                    <>
                        <form onSubmit={this.login}>
                            {formInputs}
                            <button type='submit' className={classes.Login} disabled={!formButtonEnabled}>Log in</button>
                        </form>
                        <p className={classes.Subnote}>New to the site?<br /><span className={classes.signup} onClick={this.switchToSignUp}>Sign up</span></p>
                    </>
                );
                break;
            case 'signup':
                form = (
                    <form onSubmit={this.signup}>
                        {formInputs}
                        <button type='submit' className={classes.Login} disabled={!formButtonEnabled}>Sign up</button>
                    </form>
                );
                break;
            default: form = null;
        }
        return (
            <div>
                {errorMsg}
                {this.props.spinner ? <Spinner /> : form}
            </div>
        );
    }
};

const mapStateToProps = state => ({
    modal: state.auth.modal,
    error: state.auth.error,
    spinner: state.auth.spinner
});

const mapDispatchToProps = dispatch => ({
    showModal: (content) => dispatch(actions.showModal(content)),
    login: (email, password) => dispatch(actions.login(email, password)),
    signup: (email, password, username) => dispatch(actions.signup(email, password, username))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);